<template>
  <div class="<md:h-screen">
    <div class="relative <md:h-full">
      <base-picture
        class="full"
        fit="cover"
        :props-img="{ class: 'w-full', style: 'object-position: top right', role: 'presentation' }"
        :src="{
          sm: 'https://assets.thenorthface.eu/image/upload/v1723123860/240328-TNF-ss24-404-lp-m.jpg',
          md: 'https://assets.thenorthface.eu/image/upload/v1723123860/240328-TNF-ss24-404-lp-m.jpg',
          lg: 'https://assets.thenorthface.eu/image/upload/v1723186443/240328-TNF-SS24-404-LP-RIGHT.jpg',
        }"
      />
      <div class="absolute-0 <lg:flex">
        <div class="px-4 py-8 md:max-w-2/3">
          <h1 class="display-2">
            {{ $t.maintenance.ERR404.title }}
          </h1>
          <p class="mt-4 ws-pre-wrap lg:text-md">
            <base-interpolator :content="$t.maintenance.ERR404.message">
              <template #admin="{ args: [message] }">
                <vf-link to="mailto:tnfsupport@vfc.com">
                  {{ message }}
                </vf-link>
              </template>
            </base-interpolator>
          </p>
          <vf-button class="mt-8 lg:mt-4 <md:w-full" @click="clearError({ redirect: '/' })">
            {{ $t.maintenance.ERR4xx.cta }}
          </vf-button>
          <p class="mt-8 lg:mt-4 lg:text-md">
            <base-interpolator :content="$t.maintenance.ERR404.help">
              <template #contact="{ args: [message] }">
                <vf-link to="/help-section/contact-us">
                  {{ message }}
                </vf-link>
              </template>
            </base-interpolator>
          </p>
          <lazy-cms-section
            v-if="eSpots?.['lazy-main']"
            class="mb-20 mt-8 <lg:mb-16"
            :section="eSpots['lazy-main']"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { $feature } = useNuxtApp()
const eSpots = $feature.enable404Espots && (await useCms().getSections.error()).data.value?.content.sectionsMap
</script>
