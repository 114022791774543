import type { Experiment } from '#core/server/types/featureFlags'

const baseEvent = {
  event: 'launchDarklyActiveExperiences',
  eventCategory: 'LaunchDarkly',
  eventAction: 'Active Experiences',
  eventLabel: undefined,
  nonInteractive: true,
  customMetrics: {},
  customVariables: {},
  source: 'FE',
  _clear: true,
}

export default {
  onActiveExperiences: (customVariables: Record<string, string> = {}) => {
    const experiments = useFeatureFlagsExperiments()
    const launchDarklyExperience
        = Object.entries(experiments)
          .map(
            ([key, { value, variationIndex }]: [string, Experiment]) =>
              ({ key, value: JSON.stringify(value), variationIndex })
          )

    return {
      ...baseEvent,
      launchDarklyExperience,
      customVariables
    }
  },
}
