/**
 * FeatureFlags Nuxt Plugin
 * Defines a Nuxt plugin for retrieving feature flags
 * Merges local flags overrides with LD flags
 * @returns {FeatureFlags} - feature flag list
 * @example
 * const { sampleFeatureFlag } = useFeatureFlags()
 * @category Plugin
 */

import type { FeatureFlags } from '#core/server/types/featureFlags'

declare module '#app' {
  interface NuxtApp {
    $feature: FeatureFlags
  }
}

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $feature: FeatureFlags
  }
}

export default defineNuxtPlugin({
  enforce: 'pre',
  async setup({ hook }) {
    const { launchDarklyClientKey } = useRuntimeConfig().public
    const featureFlags = useFeatureFlags()
    const experiments = useFeatureFlagsExperiments()
    const route = useRoute()

    const queryParameter = ref(route.query.ldPassthrough?.toString() ? route.query.ldPassthrough?.toString() : '')
    const ldPassthrough = useFeatureFlagsPassthroughHeader().value || queryParameter.value
    const settings: any = useApiHeaders() || {}

    const headers = {
      ...settings,
      locale: useLocale(),
      ...(ldPassthrough ? { 'x-ld-passthrough': ldPassthrough } : {})
    }

    // The server plugin will not have run on CSR-only pages,
    // and we'll only have the default empty object state,
    // we need to fetch the flags from the Nitro endpoint via an ajax call
    if (!Object.keys(featureFlags).length)
      Object.assign(featureFlags, await $fetch('/fapi/featureFlags', { headers }))

    if (featureFlags.useLdClientSdk) {
      try {
        hook('app:mounted', async () => {
          // Imported at runtime so it gets split into its own chunk
          // and is only loaded when needed
          const { initialize } = await import('launchdarkly-js-client-sdk')

          const localFlags = useLocalFeatureFlags()
          const userId = featureFlags.allowLdExperiments ? useCookie('x-usid').value : null
          const context = getLDContext(headers, userId)
          const client = initialize(
            launchDarklyClientKey,
            context,
            { streaming: true }
          )

          await client.waitForInitialization()

          const mergeFeatureFlags = async () => {
            const updatedFlags = { ...featureFlags, ...client.allFlags(), ...localFlags.value }
            if (JSON.stringify(featureFlags) !== JSON.stringify(updatedFlags))
              Object.assign(featureFlags, updatedFlags)

            if (featureFlags.allowLdExperiments)
              Object.assign(experiments, await getExperiments(featureFlags, context, client))
          }

          // We need to merge feature flags immediately
          // because when we have experiments the value can be different from
          // default value provided from LD as experiments randomly provide
          // value from experiment variants
          // so we need to wait until client is initialized
          // because during the initialization it makes a call
          // to LD to fetch experiments
          await mergeFeatureFlags()

          // Reevaluate all flags on CSR.
          // Listening for changes in real time as intended
          // can have a negative effect on performance
          // due to the extensive usage of lazy hydration.
          // This is a compromise solution to get the client SDK in use
          // until a better solution can be found for making the real-time
          // flag evaluation work well with the app's use of lazy hydration
          useRouter().afterEach(mergeFeatureFlags)

          // re-identify the user context when logged-in status changes
          // after logging-in, wait for profile details to be fetched
          if (featureFlags.allowLdExperiments) {
            const auth = useAuthStore()
            const user = useUserStore()
            watch(() => auth.loggedIn, () => {
              if (auth.loggedIn) {
                whenever(() => user.details?.email, () => {
                  client.identify(getLDContext(headers, userId, auth, user))
                  mergeFeatureFlags()
                }, { immediate: true })
              }
              else {
                client.identify(getLDContext(headers, userId))
                mergeFeatureFlags()
              }
            }, { immediate: true })
          }
        })
      }
      catch (err: any) {
        log.error('[Canvas:LD:Client] Failed to retrieve feature flags, using state', err)
      }
    }

    return {
      provide: {
        feature: featureFlags
      }
    }
  }
})
