import validate from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+reset@0.61_m2xsmtdkzpe2saapsxyay2dfii/node_modules/nuxt/dist/pages/runtime/validate.js";
import header_45global from "/opt/build/repo/domains/core/middleware/header.global.ts";
import i18n_45global from "/opt/build/repo/domains/core/middleware/i18n.global.ts";
import _15_45to_45canvas_45url_45global from "/opt/build/repo/domains/commerce/middleware/15ToCanvasUrl.global.ts";
import auto_45promo_45code_45global from "/opt/build/repo/domains/commerce/middleware/autoPromoCode.global.ts";
import dialogs_45global from "/opt/build/repo/domains/commerce/middleware/dialogs.global.ts";
import seo_45meta_45global from "/opt/build/repo/domains/commerce/middleware/seoMeta.global.ts";
import preserve_45query_45global from "/opt/build/repo/domains/content/middleware/preserveQuery.global.ts";
import manifest_45route_45rule from "/opt/build/repo/node_modules/.pnpm/nuxt@3.11.2_@netlify+blobs@7.3.0_@parcel+watcher@2.4.1_@types+node@22.10.1_@unocss+reset@0.61_m2xsmtdkzpe2saapsxyay2dfii/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  header_45global,
  i18n_45global,
  _15_45to_45canvas_45url_45global,
  auto_45promo_45code_45global,
  dialogs_45global,
  seo_45meta_45global,
  preserve_45query_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "plp-store-filter": () => import("/opt/build/repo/domains/commerce/middleware/plpStoreFilter.ts")
}